<script>
import TeacherListItemsStudentComment
from '@/components/common/teacher-list-items/teacher-list-items-student-comment.vue'

export default {
  name: 'TeacherListItemsHighlights',
  components: { TeacherListItemsStudentComment },
  inject: ['teacher'],
  props: {
    showFull: {
      type: Boolean,
      default: false
    },
    maxShow: {
      type: Number,
      default: 3
    }
  },
  computed: {
    showReadMore () {
      return this.teacher?.bestStudentComment?.student_fullname
    },
    list () {
      return this.teacher?.teacherHighlights ? this.teacher.teacherHighlights.slice(0, this.maxShow) : []
    }
  }
}
</script>

<template>
  <div>
    <div v-if="list.length > 0" class="teacher-list-highlights">
      <p class="teacher-list-highlights__title">
        {{ $t('titles.highlights') }}:
      </p>
      <ul class="teacher-list-highlights__list">
        <li v-for="(item,key) in list" :key="key">
          <span>√</span>
          <p> {{ item }}</p>
        </li>
      </ul>
      <BaseButton
        v-if="showReadMore && !showFull"
        :label="$t('common.actions.read_more') + '...'"
        flat
        light
        size="xs-sm"
        underline
        @click="$emit('toggleHeight', true)"
      />
    </div>
    <div v-if="teacher.bestStudentComment && showFull">
      <BaseSeparator class="my-4 lg-hidden-block" />
      <TeacherListItemsStudentComment class="lg:mt-6" />
    </div>
    <BaseButton
      v-if="showReadMore && showFull"
      :label="$t('common.actions.hide_details') + '...'"
      flat
      light
      size="xs-sm"
      underline
      @click="$emit('toggleHeight', false)"
    />
  </div>
</template>

<style scoped lang="scss">
.teacher-list-highlights {
  &__title {
    @apply text__caption-xs--bold lg:text__body-2-md--bold text-title mb-2;
  }
  &__list {
    @apply text__caption-xs--regular lg:text__body-3-md--regular text-body-1;
    li {
      @apply mb-2 flex space-x-2;
    }
  }
}
</style>
