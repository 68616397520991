<script>
import { mapGetters } from 'vuex'
import { shortenText } from '@helper/utils'

export default {
  name: 'TeacherListItemsStudentComment',
  inject: ['teacher'],
  computed: {
    ...mapGetters('local', ['getTutorListDesignColor']),
    comment () {
      if (this.teacher?.bestStudentComment?.student_comment) {
        return shortenText(this.teacher?.bestStudentComment?.student_comment, 170, true)
      }
      return null
    },
    studentName () {
      return this.teacher?.bestStudentComment?.student_fullname
    },
    date () {
      return this.teacher?.bestStudentComment?.created_at
    },
    bgColor () {
      const colors = {
        primary: 'primary-100',
        green: 'green-100',
        purple: 'purple-150',
        orange: 'orange-150',
        pink: 'pink-150',
        teal: 'teal-200'
      }
      return 'bg-' + colors[this.getTutorListDesignColor]
    }
  }
}
</script>

<template>
  <div v-if="comment" class="list-student-comment">
    <p
      class="list-student-comment__label"
    >
      {{ $t('labels.why_choose_teacher', { name: teacher.fullName}) }}
    </p>
    <div class="list-student-comment__box">
      <p class="list-student-comment__comment" v-html="comment" />
      <div class="flex items-center space-x-2">
        <div :class="bgColor" class="list-student-comment__avatar">
          <BaseIcon name="person" :color="getTutorListDesignColor" size="md-lg" />
        </div>
        <div class="list-student-comment__info">
          <p>{{ studentName }}</p>
          <p>{{ date }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.list-student-comment {
  &__label {
    @apply text__headline-5-xs--bold lg:text__body-2-md--bold text-title mb-2;
  }
  &__box {
    @apply border border-primary-100 rounded-lg p-4;
  }
  &__comment {
    @apply text__caption-xs--regular lg:text__body-3-md--regular text-body-2 mb-2;
  }
  &__avatar {
    @apply flex items-center justify-center w-10 h-10 lg:w-12 lg:h-12 rounded-lg;
  }
  &__info {
    > p:first-of-type {
      @apply text__caption-xs--bold lg:text__body-2-md--bold text-body-2 mb-1;
    }
    > p:last-of-type {
      @apply text__caption-1-xs--regular lg:text__body-3-md--regular text-label-text;
    }
  }
}
</style>
