<template>
  <div class="top-tutors-groups-wrapper">
    <div class="top-tutors-groups">
      <div
        v-for="course in courses"
        :key="course.id"
        class="top-tutors-groups__item border-b-4"
        :class="[borderColor(course), {'top-tutors-groups__item--bold': courseIsActive(course)}]"
        @click="$emit('clicked', course)"
      >
        {{ course.name }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TopTutorsGroups',
  props: {
    courses: {
      type: Array,
      default: () => []
    },
    activeCourse: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    designColors () {
      return {
        primary: {
          color: 'primary',
          bg: 'bg-primary',
          text: 'text-primary',
          bg_disable: 'bg-primary-100',
          border: 'border-primary',
          border_disable: 'border-primary-100'
        },
        green: {
          border: 'border-green',
          border_disable: 'border-green-100'
        },
        purple: {
          border: 'border-purple',
          border_disable: 'border-purple-150'
        },
        orange: {
          border: 'border-orange',
          border_disable: 'border-orange-150'
        },
        pink: {
          border: 'border-pink',
          border_disable: 'border-pink-150'
        },
        teal: {
          border: 'border-teal',
          border_disable: 'border-teal-200'
        }
      }
    }
  },
  methods: {
    courseIsActive (course) {
      return this.activeCourse?.id === course?.id
    },
    borderColor (course) {
      const c = course?.design_color?.toLowerCase()
      return this.courseIsActive(course)
        ? (this.designColors[c]?.border || 'border-primary')
        : (this.designColors[c]?.border_disable || 'border-primary-100')
    }
  }
}
</script>

<style scoped lang="scss">
.top-tutors-groups-wrapper {
  @apply w-full overflow-x-auto mb-4;
  &::-webkit-scrollbar {
    display: none;
  }
}
.top-tutors-groups {
  @apply flex items-center justify-start lg:justify-center space-x-1 w-max lg:w-full;
  &__item {
    @apply text__caption-xs--regular lg:text__body-2-md--regular px-2 py-2 lg:px-5 lg:py-3 cursor-pointer text-body-1;
    &--bold {
      @apply font-bold text-title;
    }
  }
}
</style>
