<script>
export default {
  name: 'TopTutorsSkeleton'
}
</script>

<template>
  <div class="top-tutors-skeleton">
    <div class="top-tutors-skeleton__list">
      <div v-for="i in 3" :key="i" class="top-tutors-skeleton__item">
        <div class="flex items-center space-x-6">
          <div class="w-2/5">
            <div class="top-tutors-skeleton__avatar" />
          </div>
          <div class="w-3/5 overflow-hidden">
            <div v-for="y in 3" :key="y" class="top-tutors-skeleton__line  mb-3" />
          </div>
        </div>
        <div class="mt-4 text-left h-[140px]">
          <div v-for="y in 3" :key="y" class="top-tutors-skeleton__line  mb-4" />
        </div>
        <div class="mt-8 text-center">
          <BaseSeparator />
          <div class="top-tutors-skeleton__word mt-8 mb-2" />
        </div>
      </div>
    </div>
    <div class="top-tutors-skeleton__word mt-14 mb-2 lg-block-hidden" />
  </div>
</template>

<style scoped lang="scss">
.top-tutors-skeleton {
  &__list {
    @apply flex space-x-6 items-center justify-between w-[940px] lg:w-full
  }
  &__item {
    @apply border border-primary-50 p-8 rounded-lg lg:w-full w-[400px] pb-2 flex-1;
  }
  &__avatar {
    @apply size-[87px] lg:size-[124px] rounded-full bg-primary-50 animate-pulse;
  }
  &__line {
    @apply w-full h-2 bg-primary-50 rounded animate-pulse;
  }
  &__word {
    @apply w-14 h-2 bg-primary-50 rounded mx-auto animate-pulse;
  }
}
</style>
